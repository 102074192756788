import Default from '@fiverr-private/footer/entries/Default';
import { Loader } from '@fiverr-private/layout-component';

export const loader: Loader = async (hydrateComponent, root) => {
    const footerObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(async (entry) => {
            if (entry.isIntersecting) {
                await hydrateComponent();
                observer.unobserve(entry.target);
            }
        });
    });

    footerObserver.observe(root);
};

export default Default;
